define("spotassist/utils/permissions-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import Ember from 'ember';
  var _default = _exports.default = {
    findPermissionsWithName(session, permissionName, dropzone) {
      var permissions = [];
      if (dropzone && session && session.get('userAcl')) {
        var dropzonePermissions = session.get('userAcl.all_permissions').filterBy('permission_name', permissionName).filterBy('type', 'dropzone');

        /*
        dropzonePermissions.forEach(function(dropzonePermission) {
          var aaa = dropzonePermission.get('object_id');
          console.log(aa);
        });
        */

        permissions = dropzonePermissions.filterBy('object_id', dropzone.get('id'));
      }
      return permissions;
    },
    findMyPermissionsWithName(session, permissionName, dropzone) {
      return this.findPermissionsWithName(session, permissionName, dropzone).filterBy('user_email', session.get('user.email'));
    },
    findDropzonesIdsWithPermissionName(session, store, permissionName) {
      var dropzoneIds = [];
      if (session && session.get('userAcl.all_permissions')) {
        var administer_dropzone_owners_permissions = session.get('userAcl.all_permissions').filterBy('permission_name', permissionName).filterBy('user_email', session.get('user.email'));
        administer_dropzone_owners_permissions.forEach(function (permission) {
          var dzId = permission.get('object_id');
          dropzoneIds.push(dzId);
        });
      }
      if (session && session.get('userAcl.permissions')) {
        var manage_dropzone_permissions = session.get('userAcl.permissions').filterBy('permission_name', permissionName).filterBy('user_email', session.get('user.email'));
        manage_dropzone_permissions.forEach(function (permission) {
          var dzId = permission.object_id;
          dropzoneIds.push(dzId);
        });
      }
      return dropzoneIds.uniq();
    },
    findAdministeredDropzones(session, store, addRanch) {
      var dropzones = [];
      if (session.get('userAcl.hasAdministerDropzoneOwnersAccess')) {
        //dropzones = store.findAll('dropzone');
        if (addRanch) {
          dropzones.push(store.findRecord('dropzone', "13"));
        }
      } else {
        var dropzoneIds = [];
        if (addRanch) {
          dropzoneIds.push("13");
        }
        var administerDropzoneIds = this.findDropzonesIdsWithPermissionName(session, store, 'administer_dropzone');
        if (administerDropzoneIds) {
          administerDropzoneIds.forEach(function (dropzoneId) {
            if (dropzoneIds.indexOf(dropzoneId) < 0) {
              dropzoneIds.addObject(dropzoneId);
            }
          });
        }
        var manageDropzoneIds = this.findDropzonesIdsWithPermissionName(session, store, 'manage_dropzone');
        if (manageDropzoneIds) {
          manageDropzoneIds.forEach(function (dropzoneId) {
            if (dropzoneIds.indexOf(dropzoneId) < 0) {
              dropzoneIds.addObject(dropzoneId);
            }
          });
        }
        dropzoneIds.forEach(function (dropzoneId) {
          dropzones.push(store.findRecord('dropzone', dropzoneId));
        });
      }
      return dropzones.uniq();
    }
  };
});