define("spotassist/services/user-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    satteliteMode: false,
    openNav() {
      var navElement = document.getElementById("mySidenav");
      if (navElement) {
        navElement.style.width = "250px";
        document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
      }
    },
    closeNav() {
      var navElement = document.getElementById("mySidenav");
      if (navElement) {
        navElement.style.width = "0";
        document.body.style.backgroundColor = "white";
      }
    },
    /*
    setUserToken(token) {
      if(token) {
        Ember.$.ajaxSetup({
              headers : {
                  'Authorization':'Token '+this.get('session.currentUser.token')
              }
            });
      } else {
        Ember.$.ajaxSetup({
              headers : {
              }
            });
      }
    },
    */

    tokenObserver: Ember.observer('token', function () {
      this.set('temporary_token', this.get('token'));
    })
  });
});