define("spotassist/controllers/what-is-bigpicture", ["exports", "spotassist/utils/dropzone-search", "spotassist/mixins/requestable-weather", "spotassist/config/environment", "spotassist/utils/global-error-handler", "spotassist/utils/load-current-user"], function (_exports, _dropzoneSearch, _requestableWeather, _environment, _globalErrorHandler, _loadCurrentUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend(_requestableWeather.default, {
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    dialog: Ember.inject.service(),
    metrics: Ember.inject.service(),
    scroller: Ember.inject.service(),
    cookies: Ember.inject.service('cookies'),
    queryParams: ['dz_id'],
    dz_id: null,
    init: function () {
      this._super();
      var that = this;
      this.get("dialog").on("created", presenter => {
        that.set('presenter', presenter);
      });

      //this.loadDropzones();
    },
    /*
    loadDropzones() {
      let that = this;
      console.error('this.store.findAll in what-is-big-picture');
      this.store.findAll('dropzone', {reload: true, null_location: "true"}).then(function(dropzones) {
        that.set('cache', dropzones);
      }, function(error) {
        var message = "Error loading store: dropzone";
        console.error(message+error);
        globalErrorHandler(error, message);
      });
    },
    */

    trackScrollAction(scrollTo) {
      Ember.get(this, 'metrics').trackEvent({
        event: scrollTo,
        unit: 'dropzone',
        value: this.get('currentDropzone.dz_name')
      });
    },
    doScrollToHtmlId(htmlId) {
      var scrollOptions = {};
      this.get('scroller').scrollVertical(htmlId, scrollOptions);
    },
    actions: {
      searchBigPictureAsync(query) {
        var store = this.get('store');
        var cacheContainer = this;
        return (0, _dropzoneSearch.default)(query, store, cacheContainer);
      },
      showBigPicture(dropzone) {
        this.get('presenter').accept();
        this.get("router").transitionTo('dropzones.bigpicture', [dropzone.get('id')]);
      },
      goToRoute(route) {
        this.get("dialog");
        this.get('presenter').accept();
        this.get("router").transitionTo(route);
      },
      checkBigPicture(dropzone) {
        //this.get("router").transitionTo('dropzones.bigpicture', [dropzone.get('id')]);
        this.doCheckBigPicture(dropzone);
      },
      afterScrollAction(scrollTo) {
        this.trackScrollAction(scrollTo);
      },
      openVideo() {
        //console.info("openVideo");

        var page = Ember.$(".page");
        var popupIframe = Ember.$("#video-iframe");
        var mouseIcon = Ember.$(".mouse-icon");
        if (!this.get("videoUp")) {
          Ember.get(this, 'metrics').trackEvent({
            event: "See Big Picture Video"
          });
          if (popupIframe.attr("src") == '') {
            popupIframe.attr("src", "https://www.youtube.com/embed/83Ea1lOs3Dw?autoplay=1&wmode=transparent");
          }
          page.addClass("show-popup");
          mouseIcon.addClass("hidden");
        } else {
          popupIframe.attr("src", "");
          page.removeClass("show-popup");
          mouseIcon.removeClass("hidden");
        }
        this.set("videoUp", !this.get("videoUp"));
      },
      scrollToPrices(presenter) {
        this.doScrollToHtmlId("#pricing");
        presenter.accept();
        //this.trackScrollAction("Scroll To Pricing");
      },
      iWantIt() {
        Ember.get(this, 'metrics').trackEvent({
          event: "Vote Dropzone Big Picture",
          unit: 'dropzone',
          value: this.get('currentDropzone.dz_name')
        });
        var that = this;
        var voteRecord = this.get('store').createRecord('bigpicture-vote', {
          "dropzone": that.get('currentDropzone.id')
        });
        voteRecord.save().then(function () {
          that.requestVotesCount();
        }, function (error) {
          if (error.message.indexOf("You cannot") < 0) {
            var message = 'Unable to vote for dropzone ' + that.get('currentDropzone.dz_name') + ' error:' + error.message;
            console.error(message);
            (0, _globalErrorHandler.default)(error, message);
          }
          that.requestVotesCount();
        });
        this.get("dialog").alert("dialogs/i-want-it", this, {
          acceptHandler: "scrollToPrices"
        });
      },
      requestTrial() {
        Ember.get(this, 'metrics').trackEvent({
          event: "Request trial",
          unit: 'dropzone',
          value: this.get('currentDropzone.dz_name')
        });
        var data = {
          email: this.get('trialEmail'),
          name: this.get('trialName'),
          dropzone_id: this.get('currentDropzone.id'),
          dropzone: this.get('currentDropzone.dz_name')
        };
        var that = this;
        this.set('requestingTrial', true);
        this.set('requestingTrialError', false);
        let csrftoken = this.get('cookies').read('csrftoken');
        Ember.$.ajax({
          type: "POST",
          headers: {
            "X-CSRFToken": csrftoken
          },
          url: _environment.default.adapter_host + '/trial-bigpicture/',
          data: data,
          dataType: 'json',
          success: function () {
            console.info("request trial success");
            that.set('requestingTrial', false);
            that.set('requestingTrialError', false);
            that.set('currentDropzone', null);
          },
          error: function (error) {
            var message = 'Unable to request trial with ' + data + ' credentials error:' + JSON.stringify(error.responseJSON);
            console.error(message);
            (0, _globalErrorHandler.default)(error, message);
            that.set('requestingTrial', false);
            that.set('requestingTrialError', true);
          }
        });
      },
      //testSuccessMessage(product, amount, token, args) {
      testSuccessMessage() {
        console.info("testSuccessMessage");
        this.get("dialog").alert("dialogs/purchase-success", this);
      },
      //processStripeToken(product, amount, token, args) {
      processStripeToken(product, amount, token) {
        // Send token to the server to associate with account/user/etc

        var that = this;
        var purchaseRecord = this.get('store').createRecord('bigpicture-purchase', {
          "product": product,
          "amount": amount,
          "email": token.email,
          "stripe_token": token.id,
          "dz_id": this.get('currentDropzone.id')
        });
        purchaseRecord.save().then(function () {
          console.info('processStripeToken SUCCESS');
          (0, _loadCurrentUser.default)(that.get("session"), that.get("store"));
          that.get("dialog").alert("dialogs/purchase-success", that);
        }, function (error) {
          if (error.message.indexOf("You cannot") < 0) {
            var message = 'Unable to save purchase for ' + token.email + ' error:' + error.message;
            console.error(message);
            (0, _globalErrorHandler.default)(error, message);
          }
          that.get("dialog").alert("dialogs/purchase-error");
        });
      }
    },
    requestTrialSuccess: Ember.computed('requestingTrial', 'requestingTrialError', function () {
      if (this.get('requestingTrial') === undefined || this.get('requestingTrial')) {
        return false;
      } else if (this.get('requestingTrialError')) {
        return false;
      } else {
        return true;
      }
    }),
    requestTrialDisabled: Ember.computed('trialEmail', 'trialName', 'currentDropzone', function () {
      var currentDropzone = this.get('currentDropzone');
      var email = this.get('trialEmail');
      var name = this.get('trialName');
      if (Ember.isEmpty(email)) {
        return true;
      }
      if (email.indexOf('@') < 0 || email.indexOf('.') < 0) {
        return true;
      }
      if (Ember.isEmpty(name)) {
        return true;
      }
      return currentDropzone == null;
    }),
    currentDropzoneVotesObserver: Ember.observer('currentDropzone', function () {
      this.requestVotesCount();
    }),
    requestVotesCount() {
      var that = this;
      return this.get('store').query('bigpicture-vote', {
        dropzone_id: that.get('currentDropzone.id')
      }).then(function (votes) {
        that.set('currentDropzoneVotes', votes.content.length);
      });
    },
    doCheckBigPicture(dropzone) {
      //this.get("router").transitionTo('dropzones.bigpicture', [dropzone.get('id')]);
      Ember.get(this, 'metrics').trackEvent({
        event: "Check Dropzone Big Picture",
        unit: 'dropzone',
        value: dropzone.get('dz_name')
      });
      this.set('currentDropzone', dropzone);
      this.set('windaloftStations', []);
      this.set('metarStations', []);
      this.set('rawinsondeStations', []);
      this.requestDropzoneWeather(dropzone);
    },
    dzIdObserver: Ember.observer('dz_id', function () {
      //console.log('will load dz id='+this.get('dz_id'));
      var that = this;
      this.get('store').findRecord('dropzone', this.get('dz_id')).then(function (dropzone) {
        that.doCheckBigPicture(dropzone);
        Ember.run.later(this, function () {
          that.doScrollToHtmlId("#pricing");
        }, 1000);
      });
    }),
    facebookCurrentDropzoneUrl: Ember.computed('currentDropzone', function () {
      return "https://dropzones.spotassist.com/#!/dropzones/" + this.get('currentDropzone.id') + "/bigpicture";
    }),
    dropzoneReady: Ember.computed('windaloftStation', 'rawinsondeStation', 'metarStation', 'weatherLoadingProgress', function () {
      if (this.get('windaloftStation') && this.get('metarStation') || this.get('rawinsondeStation') && this.get('metarStation') || this.get('weatherLoadingProgress')) {
        return true;
      } else {
        return false;
      }
    })
  });
});