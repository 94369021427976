define("spotassist/routes/what-is-bigpicture", ["exports", "spotassist/mixins/trackable-route", "spotassist/mixins/seo-route"], function (_exports, _trackableRoute, _seoRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_trackableRoute.default, _seoRoute.default, {
    actions: {
      didTransition() {
        this._super(...arguments);
        this.trackPage();
      }
    },
    afterModel(model) {
      let title = 'What is Spot Assist "Big Picture';
      let description = 'Big Picture" - Real Time Weather on the big screen at Your dropzone. Boiled down to what skydivers really need.';
      let imageUrl = 'http://www.spotassist.com/wp-content/uploads/2018/04/bigpicture-spotassist-faces.png';
      this.setHeadTags(model, title, description, imageUrl);
    }
  });
});