define("spotassist/components/stations-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      setPreferredStation(station) {
        this.get('weatherSource').setPreferredStation(station, this.get('stationType'));
      }
    }
  });
});