define("spotassist/routes/dropzones/index", ["exports", "spotassist/mixins/trackable-route", "spotassist/mixins/seo-route"], function (_exports, _trackableRoute, _seoRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_trackableRoute.default, _seoRoute.default, {
    actions: {
      mapClicked() /*event*/{
        //console.log('mapClicked: ');

        this.controller.set('markerClicked', false);
        //this.controller.set('selectedDropzone', null);
        this.get('user-settings').closeNav();
      },
      markerLocationChanged(event) {
        var selectedDropzone = this.controller.get('selectedDropzone');
        selectedDropzone.set('lat', event.target._latlng.lat);
        selectedDropzone.set('lon', event.target._latlng.lng);
      },
      openNavAction() {
        this.get('user-settings').openNav();
      },
      closeNavAction() {
        this.get('user-settings').closeNav();
      },
      didTransition() {
        this._super(...arguments);
        this.trackPage();
      }
    },
    beforeModel: function () {
      this.get('user-settings').closeNav();
      this.get('user-settings').set('nav-bar-map-extension', true);
    },
    activate() {
      //console.log('activate');
      console.info('Dropzones Atlas activated');
      this.controllerFor('dropzones.index').setRouteActive(true);
      //this.get('controller').set('routeActive', true);
    },
    deactivate() {
      //console.log('deactivate');
      console.info('Dropzones Atlas deactivated');
      this.controllerFor('dropzones.index').setRouteActive(false);
      //this.get('controller').set('routeActive', false);
    },
    model() {
      return null;
    },
    afterModel(model) {
      let title = 'Spot Assist World Dropzones Atlas';
      let description = 'Crowd sourced dropzones around the world. Jumpers fine tune the data daily - landing target, landing direction etc.';
      let imageUrl = 'http://www.spotassist.com/wp-content/uploads/2017/02/dropzones-spotassist.png';
      this.setHeadTags(model, title, description, imageUrl);
    }
  });
});