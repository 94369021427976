define("spotassist/utils/weather-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import Ember from 'ember';
  /* global com */
  /* global java */
  var _default = _exports.default = {
    windaloftFromStation(windaloftStation, rawinsondeStation) {
      if (!windaloftStation && !rawinsondeStation) {
        return null;
      }
      // windaloft
      var valid_from = null;
      var valid_to = null;
      var flightlevels = new java.util.ArrayList();
      var flightlevelsMap = [];
      if (windaloftStation) {
        windaloftStation.windaloft[0].flightlevels.forEach(function (flightlevelObject) {
          var flightlevel = flightlevelObject.flightlevel ? flightlevelObject.flightlevel : -1;
          var direction = flightlevelObject.direction ? flightlevelObject.direction : -1;
          var speed = flightlevelObject.speed ? flightlevelObject.speed : -1;
          var temperature = flightlevelObject.temperature ? flightlevelObject.temperature : Number.MIN_VALUE;
          var flightLevelObject = new com.livewings.spotassist.library.json.FlightLevel(flightlevel, direction, speed, temperature);
          if (!flightlevelsMap[flightlevel]) {
            flightlevels.add(flightLevelObject);
            flightlevelsMap[flightlevel] = flightLevelObject;
          }
        });
        valid_from = windaloftStation.windaloft[0].valid_from ? windaloftStation.windaloft[0].valid_from : null;
        valid_to = windaloftStation.windaloft[0].valid_to ? windaloftStation.windaloft[0].valid_to : null;
      }
      if (rawinsondeStation) {
        rawinsondeStation.rawinsonde[0].flightlevels.forEach(function (flightlevelObject) {
          var flightlevel = flightlevelObject.height_m ? Math.round(flightlevelObject.height_m * 3.28084 / 1000) : -1;
          var direction = flightlevelObject.direction_from ? flightlevelObject.direction_from : -1;
          var speed = flightlevelObject.speed_ms ? flightlevelObject.speed_ms * 1.94384 : -1;
          var temperature = flightlevelObject.temp_c ? flightlevelObject.temp_c : Number.MIN_VALUE;
          var flightLevelObject = new com.livewings.spotassist.library.json.FlightLevel(flightlevel, direction, speed, temperature);
          if (!flightlevelsMap[flightlevel]) {
            flightlevels.add(flightLevelObject);
            flightlevelsMap[flightlevel] = flightLevelObject;
          }
        });
        valid_from = rawinsondeStation.rawinsonde[0].valid ? rawinsondeStation.rawinsonde[0].valid : null;
        valid_to = rawinsondeStation.rawinsonde[0].valid ? rawinsondeStation.rawinsonde[0].valid : null;
      }
      var windaloft = new com.livewings.spotassist.library.json.Windaloft(valid_from, valid_to, flightlevels);
      return windaloft;
    },
    metarFromStation(metarStation) {
      if (!metarStation) {
        return null;
      }

      // metar
      var skyConditions = new java.util.ArrayList();
      metarStation.get('metarObject').sky_conditions.forEach(function (skyconditionObject) {
        var sky_cover = skyconditionObject.sky_cover ? skyconditionObject.sky_cover : null;
        var sky_cover_human = skyconditionObject.sky_cover_human ? skyconditionObject.sky_cover_human : null;
        var cloud_base_ft_agl = skyconditionObject.cloud_base_ft_agl ? skyconditionObject.cloud_base_ft_agl : 0;
        var skyCondition = new com.livewings.spotassist.library.json.SkyCondition(sky_cover, sky_cover_human, cloud_base_ft_agl);
        skyConditions.add(skyCondition);
      });
      var temp_c = metarStation.get('metarObject.temp_cValue') ? metarStation.get('metarObject.temp_cValue') : Number.MIN_VALUE;
      var dewpoint_c = metarStation.get('metarObject').dewpoint_c ? metarStation.get('metarObject').dewpoint_c : Number.MIN_VALUE;
      var altim_in_hg = metarStation.get('metarObject').altim_in_hg ? metarStation.get('metarObject').altim_in_hg : Number.MIN_VALUE;
      var wind_dir_degrees = metarStation.get('metarObject.wind_dir_degreesValue') ? metarStation.get('metarObject.wind_dir_degreesValue') : -1;
      var wind_speed_kt = metarStation.get('metarObject.wind_speed_ktValue') ? metarStation.get('metarObject.wind_speed_ktValue') : -1;
      var wind_gust_kt = metarStation.get('metarObject').wind_gust_kt ? metarStation.get('metarObject').wind_gust_kt : -1;
      var wx_string = metarStation.get('metarObject').ws_string ? metarStation.get('metarObject').wx_string : null;
      var visibility_statute_mi = metarStation.get('metarObject').visibility_statute_mi ? metarStation.get('metarObject').visibility_statute_mi : -1;
      var observation_time = metarStation.get('metarObject').observation_time ? metarStation.get('metarObject').observation_time : null;
      var metar = new com.livewings.spotassist.library.json.Metar(temp_c, dewpoint_c, altim_in_hg, wind_dir_degrees, wind_speed_kt, wind_gust_kt, wx_string, visibility_statute_mi, observation_time, skyConditions);
      return metar;
    }
  };
});