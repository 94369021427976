define("spotassist/routes/auth/password-reset-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model(params) {
      this.set('params', params);
      return null;
    },
    setupController: function (controller, model) {
      controller.set('params', this.get('params'));
      this._super(controller, model);
    }
  });
});