define("spotassist/initializers/dropzones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    // application.inject('route', 'foo', 'service:foo');
    application.inject('controller', 'dropzones_service', 'service:dropzones');
    application.inject('route', 'dropzones_service', 'service:dropzones');
  }
  var _default = _exports.default = {
    before: 'ember-simple-auth',
    initialize
  };
});