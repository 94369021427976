define("spotassist/instance-initializers/setup-routes", ["exports", "torii/bootstrap/routing", "torii/configuration", "torii/compat/get-router-instance", "torii/compat/get-router-lib", "torii/router-dsl-ext"], function (_exports, _routing, _configuration, _getRouterInstance, _getRouterLib, _routerDslExt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'torii-setup-routes',
    initialize(applicationInstance /*, registry */) {
      const configuration = (0, _configuration.getConfiguration)();
      if (!configuration.sessionServiceName) {
        return;
      }
      let router = (0, _getRouterInstance.default)(applicationInstance);
      var setupRoutes = function () {
        let routerLib = (0, _getRouterLib.default)(router);
        var authenticatedRoutes = routerLib.authenticatedRoutes;
        var hasAuthenticatedRoutes = !Ember.isEmpty(authenticatedRoutes);
        if (hasAuthenticatedRoutes) {
          (0, _routing.default)(applicationInstance, authenticatedRoutes);
        }
        router.off('willTransition', setupRoutes);
      };
      router.on('willTransition', setupRoutes);
    }
  };
});