define("spotassist/routes/raob-stations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    actions: {
      removeAll() {
        //var model = this.store.findAll('raob-station');
        var model = [];
        this.controller.set('model', model);
      },
      addAll() {
        var model = this.store.findAll('raob-station');
        this.controller.set('model', model);
      }
      /*
      loading(transition, originRoute) {
        console.log('raobs stations are loading');
            //this.controller.set('currentlyLoading', true);
        }
        */
    },
    model() {
      return this.store.findAll('raob-station');
      //return null;
    }

    /*
      setupController(controller, model) {
          console.log("setupController");
    
    
        }
    */
  });
});