define("spotassist/components/windaloft-table", ["exports", "spotassist/utils/weather-utils"], function (_exports, _weatherUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    top: 15,
    filterBy: 'flightlevel',
    filteredLevels: Ember.computed('filterBy', 'top', 'windaloftStation', 'rawinsondeStation', function () {
      var windaloft = _weatherUtils.default.windaloftFromStation(this.get('windaloftStation'), this.get('rawinsondeStation'));
      var flightlevels = windaloft.flightlevels.array;
      if (flightlevels) {
        var top = this.get('top');
        return flightlevels.filter(function (flightlevel) {
          return flightlevel.flightlevel < top;
        });
      } else {
        return [];
      }
    }),
    sortedLevels: Ember.computed.sort('filteredLevels', 'sortDefinition'),
    sortBy: 'flightlevel',
    reverseSort: false,
    sortDefinition: Ember.computed('sortBy', 'reverseSort', function () {
      let sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
      return [`${this.get('sortBy')}:${sortOrder}`];
    })
  });
});