define("spotassist/models/acl", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var _default = _exports.default = Model.extend({
    //id: attr(),
    //user: attr(),
    email: attr(),
    permissions: _emberData.default.hasMany('permission', {
      async: false
    }),
    findPermissions(dropzone, permissionName) {
      var permissions = this.get('permissions').filterBy('permission_name', permissionName).filterBy('type', 'dropzone').filterBy('object_id', dropzone.get('id'));
      return permissions;
    }
  });
});