define("spotassist/initializers/metrics", ["exports", "spotassist/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    const application = arguments[1] || arguments[0];
    const {
      metricsAdapters = []
    } = _environment.default;
    const {
      environment = 'development'
    } = _environment.default;
    const options = {
      metricsAdapters,
      environment
    };
    application.register('config:metrics', options, {
      instantiate: false
    });
    application.inject('service:metrics', 'options', 'config:metrics');
  }
  var _default = _exports.default = {
    name: 'metrics',
    initialize
  };
});