define("spotassist/routes/dropzones/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    beforeModel: function () {
      //beforeModel: function(transition) {
      this.get('user-settings').closeNav();
      this.get('user-settings').set('nav-bar-map-extension', true);
    },
    setupController(controller) {
      //setupController(controller, error) {
      let title = 'Failed loading...';
      /*
      let code = error.code || error.get('code');
      if (code) {
        if (code >= 500) {
          title = 'Oops, there was a server error…';
        } else if (code === 404) {
          title = "Opps, can't find this one…";
        }
        controller.set('title', title);
      }
      */
      controller.set('title', title);
      this._super(...arguments);
    }
  });
});