define("spotassist/metrics-adapters/hotjar", ["exports", "ember-metrics/metrics-adapters/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    toStringExtension() {
      return 'hotjar';
    },
    init() {
      //const { apiKey, options } = Ember.get(this, 'config');
      const {
        hjid
      } = Ember.get(this, 'config');
      //this.setupService(apiKey);
      //this.setOptions(options);

      (function (h, o, t, j, a, r) {
        h.hj = h.hj || function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
        h._hjSettings = {
          hjid: hjid,
          hjsv: 5
        };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
    },
    identify(options) {
      console.info("Hotjar Track identify: " + options.id);
    },
    trackEvent(options) {
      console.info("Hotjar Track event: " + options.event);
    },
    trackPage(options) {
      console.info("Hotjar Track page: " + options.title);
    },
    alias() {
      //alias(options) {
    },
    willDestroy() {
      //willDestroy(options) {
    }
  });
});