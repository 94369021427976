define("spotassist/models/crowd-user-dropzone", ["exports", "ember-data", "spotassist/config/environment", "moment"], function (_exports, _emberData, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  /* global L */
  var _default = _exports.default = Model.extend({
    dz_id: attr('string'),
    dz_name: attr('string'),
    dz_address: attr('string'),
    lat: attr('string'),
    lon: attr('string'),
    dz_phone: attr('string'),
    dz_email: attr('string'),
    dz_url: attr('string'),
    facebook_url: attr('string'),
    dz_elevation_m: attr(),
    dz_landing_corridor: attr(),
    dz_jumprun: attr(),
    dz_pattern_strategy: attr(),
    dz_downwind_ft: attr(),
    dz_base_ft: attr(),
    dz_final_ft: attr(),
    dz_ident: attr('string'),
    unregistered: attr(),
    updated: attr('string'),
    digested: attr('string'),
    contacted: attr('string'),
    active: attr(),
    reported_by: attr(),
    dz_outs: attr({
      defaultValue() {
        return [];
      }
    }),
    dz_outs_shapes: Ember.computed('dz_outs', function () {
      var shapes = [];
      this.get('dz_outs').forEach(function (dz_out) {
        var shape = JSON.parse(dz_out.shape_geojson);
        shapes.addObject(shape);
      });
      return shapes;
    }),
    isDigestable: true,
    dzElevationFixer: Ember.observer('dz_elevation_m', function () {
      if (this.get('dz_elevation_m') === '') {
        this.set('dz_elevation_m', null);
      }
    }),
    location: Ember.computed('lat', 'lon', function () {
      return [this.get('lat'), this.get('lon')];
    }),
    displayName: Ember.computed('dz_name', function () {
      return this.get('dz_name');
    }),
    draggable: Ember.computed('selected', 'editMode', function () {
      var draggable = this.get('selected') === true && this.get('editMode') === true;
      //console.log('draggable change for '+this.get('dz_name')+' draggable:'+draggable);

      return draggable;
    }),
    icon: Ember.computed(function () {
      return new L.icon({
        //iconUrl: 'assets/images/unregistered-dropzone.png',
        iconUrl: _environment.default.rootURL + 'assets/images/spotassist_web_logo25.png',
        iconAnchor: [16, 37],
        popupAnchor: [0, -37]
      });
    }),
    landingCorridorSet: Ember.computed('dz_landing_corridor', function () {
      return this.get('dz_landing_corridor') >= 0;
    }),
    jumprunSet: Ember.computed('dz_jumprun', function () {
      return this.get('dz_jumprun') >= 0;
    }),
    landingCorridorIcon: Ember.computed(function () {
      return new L.icon({
        iconUrl: _environment.default.rootURL + 'assets/images/landing_corridor_black.png',
        iconAnchor: [36, 143],
        className: 'corridor-opacity'
      });
    }),
    jumprunIcon: Ember.computed(function () {
      return new L.icon({
        iconUrl: _environment.default.rootURL + 'assets/images/jumprun_black.png',
        iconAnchor: [36, 143],
        className: 'corridor-opacity'
      });
    }),
    rotationRad: Ember.computed('rotation', function () {
      return this.get('rotation') * (Math.PI / 180);
    }),
    rotation: Ember.computed('dz_landing_corridor', function () {
      return this.get('dz_landing_corridor');
    }),
    jumprunRotationRad: Ember.computed('jumprunRotation', function () {
      return this.get('jumprunRotation') * (Math.PI / 180);
    }),
    jumprunRotation: Ember.computed('dz_jumprun', function () {
      return this.get('dz_jumprun');
    }),
    canBeSaved: Ember.computed('hasDirtyAttributes', 'dz_name', function () {
      return this.get('hasDirtyAttributes') && this.get('dz_name');
    }),
    reportedBy: Ember.computed('reported_by', function () {
      var name = '';
      if (this.get('reported_by.first_name')) {
        name += this.get('reported_by.first_name');
      }
      if (this.get('reported_by.last_name')) {
        name += ' ' + this.get('reported_by.last_name');
      }
      return name;
    }),
    reportedClosed: Ember.computed('active', function () {
      return this.get('active') === 0;
    }),
    isDigested: Ember.computed('digested', 'updated', function () {
      if (this.get('overrideDigested') != null) {
        return this.get('overrideDigested');
      }
      return (0, _moment.default)(this.get('digested')) >= (0, _moment.default)(this.get('updated'));
    }),
    stateClassName: Ember.computed('reportedClosed', 'isDigested', 'unregistered', function () {
      if (this.get('isDigested')) {
        return 'alert-success';
      } else if (this.get('reportedClosed')) {
        return 'alert-danger';
      } else if (this.get('unregistered')) {
        return 'alert-warning';
      } else {
        return 'alert-info';
      }
    }),
    stateTitle: Ember.computed('reportedClosed', 'isDigested', 'unregistered', function () {
      if (this.get('isDigested')) {
        return 'Processed';
      } else if (this.get('reportedClosed')) {
        return 'Closed';
      } else if (this.get('unregistered')) {
        return 'New';
      } else {
        return 'Modified';
      }
    }),
    crowdEditUrl: Ember.computed('', function () {
      var params = [];
      params.push('lat=' + this.get('lat'));
      params.push('lon=' + this.get('lon'));
      params.push('zoom=17');
      //if(this.get('unregistered')) {
      params.push('crowd=true');
      //}
      return '/#/dropzones?' + params.join('&');
    }),
    changedKeys: Ember.computed('parentDropzone', function () {
      var changedKeys = [];
      var parentDropzone = this.get('parentDropzone');
      var that = this;
      if (parentDropzone) {
        var attributes = Ember.keys(parentDropzone.toJSON());
        attributes.forEach(function (attribute) {
          if (parentDropzone.get(attribute) !== that.get(attribute)) {
            changedKeys.push(attribute);
          }
        });
      }
      return changedKeys;
    }),
    allKeys: Ember.computed('', function () {
      var allKeys = [];
      var attributes = Ember.keys(this.toJSON());
      var that = this;
      attributes.forEach(function (attribute) {
        if (that.get(attribute)) {
          allKeys.push(attribute);
        }
      });
      return allKeys;
    })
  });
});