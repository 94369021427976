define("spotassist/services/dropzones", ["exports", "spotassist/utils/global-error-handler"], function (_exports, _globalErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),
    init() {
      this._super();
      console.info("Initializing dropzones service...");
      var session = this.get('session.user');
      if (!session) {
        console.warn("no session");
      }
      this.loadDropzones();
    },
    loadDropzones() {
      var that = this;

      //if(!this.get('dropzones')) {
      this.set('loadingDropzones', true);
      this.store.findAll('dropzone', {
        reload: true,
        null_location: "true"
      }).then(function (dropzones) {
        //this.store.query('dropzone', {with_null: "true"}).then(function(dropzones) {
        console.log('Loaded ' + dropzones.length + ' system dropzones');
        that.set('master_dropzones', dropzones);
        that.set('loadingDropzones', false);

        //that.set('registeredDropzones', dropzones);
      }, function (error) {
        var message = "Error loading store: dropzone";
        console.error(message + error);
        (0, _globalErrorHandler.default)(error, message);
        that.set('loadingDropzones', false);
        that.set('loadingDropzonesError', true);
      });
      //}
    },
    dropzonesReloader: Ember.observer('session.user', function () {
      //dropzonesReloader() {
      var that = this;
      if (this.get('session.user')) {
        this.store.findAll('user-dropzone', {
          reload: true,
          null_location: "true"
        }).then(function (userDropzones) {
          console.log('Loaded ' + userDropzones.length + ' user dropzones');
          that.set('user_dropzones', userDropzones);
        });
      } else {
        Ember.run(function () {
          that.store.unloadAll(); // UNLOAD ALL THE THINGS!
          that.loadDropzones();
        });
        this.set('master_dropzones', null);
        this.set('user_dropzones', null);
      }
    }),
    dropzonesMerger: Ember.observer('user_dropzones', 'master_dropzones', function () {
      var that = this;
      var dropzones = [];
      var masterDropzones = this.get('master_dropzones');
      var userDropzones = this.get('session.user') ? this.get('user_dropzones') : [];
      if (masterDropzones && userDropzones) {
        console.log('Adding master dropzones');
        masterDropzones.forEach(function (masterDropzone) {
          dropzones.addObject(masterDropzone);
        });
        if (userDropzones.length > 0) {
          console.log('Merging master and user dropzones');
        }
        userDropzones.forEach(function (userDropzone) {
          if (userDropzone.get('dz_id')) {
            var baseDropzone = that.store.peekRecord('dropzone', userDropzone.get('dz_id'));
            //var userDropzoneCorrections = {};
            if (baseDropzone) {
              userDropzone.constructor.eachAttribute(function (modelKey /*, meta*/) {
                if (userDropzone.get(modelKey) && userDropzone.get(modelKey) != -1 && baseDropzone.get(modelKey) != userDropzone.get(modelKey)) {
                  //userDropzoneCorrections[modelKey] = userDropzone.get(modelKey);
                  console.log('Merging dropzone ' + baseDropzone.get('dz_name') + '  key ' + modelKey);
                  baseDropzone.set(modelKey, userDropzone.get(modelKey));
                  //userDropzone.set(modelKey, baseDropzone.get(modelKey));
                }
              });
              baseDropzone.send('pushedData');
            }

            //dropzones.removeObject(baseDropzone);
          } else {
            dropzones.push(userDropzone);
          }
        });
        that.set('dropzones', dropzones);
        that.set('dropzonesLoaded', true);
      }
    })
  });
});