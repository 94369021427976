define("spotassist/helpers/get-attribute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    //compute(params, hash) {
    compute(params) {
      let dropzone = params[0];
      let attribute = params[1];
      return dropzone.get(attribute);
    }
  });
});