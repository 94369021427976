define("spotassist/utils/request-wu-weather", ["exports", "spotassist/utils/encode-params"], function (_exports, _encodeParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = requestWUWeather;
  function makeWURequest(weatherUrlBase, station_id, api_key, successCallback, errorCallback) {
    var params = {
      format: "json",
      units: "e",
      apiKey: api_key,
      stationId: station_id
    };
    var weatherUrl = weatherUrlBase + "?" + (0, _encodeParams.default)(params);
    var weatherObject = new XMLHttpRequest();
    weatherObject.open('GET', weatherUrl, true);
    //weatherObject.setRequestHeader('Cache-Control', 'no-cache');
    //weatherObject.setRequestHeader('Cache-Control', 'max-age=599');
    weatherObject.send();
    weatherObject.onload = function () {
      try {
        var weatherInfo = JSON.parse(weatherObject.responseText);
        if (successCallback) {
          successCallback(weatherInfo);
        }
      } catch (error) {
        if (errorCallback) {
          errorCallback(error);
        }
      }
    };
    weatherObject.onerror = function (error) {
      if (errorCallback) {
        var message = "Error loading url: " + weatherUrl;
        console.error(message + " " + error);
        errorCallback(error, message);
      }
    };
  }
  function requestWUWeather(station_id, api_key, successCallback, errorCallback) {
    var error = function (error, message) {
      if (errorCallback) {
        errorCallback(error, message);
      }
    };
    var success = function (observations) {
      var weatherUrlCurrent = "https://api.weather.com/v2/pws/observations/current";
      makeWURequest(weatherUrlCurrent, station_id, api_key, function (current) {
        //console.log(weatherInfo);
        if (successCallback) {
          successCallback(current, observations);
        }
      }, error);
    };
    var weatherUrlObservation = "https://api.weather.com/v2/pws/observations/all/1day";
    makeWURequest(weatherUrlObservation, station_id, api_key, success, error);
  }
});