define("spotassist/utils/conversion-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    convertSpeed(value, sourceUnit, destUnit) {
      if (!destUnit) {
        destUnit = "kts";
      }
      if (value <= 0) {
        return "Calm";
      }
      var speedKts = null;
      if (sourceUnit === 'm/s') {
        speedKts = value * 1.94384;
      } else if (sourceUnit === 'mh') {
        speedKts = value * 0.868976;
      } else if (sourceUnit === 'kts') {
        speedKts = value;
      }
      let result = 'null';
      if (destUnit && destUnit.toLowerCase() === 'm/s') {
        result = speedKts * 0.514444;
        result = result.toFixed(1);
      }
      if (destUnit && destUnit.toLowerCase() === 'mh') {
        result = speedKts * 1.15077945;
        result = Math.round(result);
      }
      if (destUnit && destUnit.toLowerCase() === 'kts') {
        result = speedKts;
        result = Math.round(result);
      }
      result = result + ' ' + (destUnit ? destUnit : "null");
      return result;
    },
    convertAltitude(value, sourceUnit, destUnit) {
      if (!destUnit) {
        destUnit = "ft";
      }
      var altitudeFt = null;
      if (sourceUnit === 'ft') {
        altitudeFt = value;
      }
      if (sourceUnit === 'level') {
        altitudeFt = value * 1000;
      }
      if (sourceUnit === 'm') {
        altitudeFt = value * 3.28084;
      }
      if (sourceUnit === 'mi') {
        altitudeFt = value * 5280;
      }
      let result = 'null';
      if (destUnit && destUnit.toLowerCase() === 'm') {
        result = altitudeFt * 0.3048;
      }
      if (destUnit && destUnit.toLowerCase() === 'mi') {
        result = altitudeFt * 0.000189393939;
      }
      if (destUnit && destUnit.toLowerCase() === 'ft') {
        result = altitudeFt;
      }
      result = Math.round(result / 100) * 100 + ' ' + (destUnit ? destUnit : "null");
      return result;
    },
    convertDistance(value, sourceUnit, destUnit) {
      if (!destUnit) {
        destUnit = "mi";
      }
      var altitudeFt = null;
      if (sourceUnit === 'ft') {
        altitudeFt = value;
      }
      if (sourceUnit === 'level') {
        altitudeFt = value * 1000;
      }
      if (sourceUnit === 'm') {
        altitudeFt = value * 3.28084;
      }
      if (sourceUnit === 'mi') {
        altitudeFt = value * 5280;
      }
      if (sourceUnit === 'km') {
        altitudeFt = value * 3280.8399;
      }
      let result = 'null';
      if (destUnit && destUnit.toLowerCase() === 'm') {
        result = altitudeFt * 0.3048;
      }
      if (destUnit && destUnit.toLowerCase() === 'mi') {
        result = altitudeFt * 0.000189393939;
      }
      if (destUnit && destUnit.toLowerCase() === 'ft') {
        result = altitudeFt;
      }
      if (destUnit && destUnit.toLowerCase() === 'km') {
        result = altitudeFt * 0.0003048;
      }
      result = Math.round(result / 1) * 1 + ' ' + (destUnit ? destUnit : "null");
      return result;
    },
    convertTemperature(value, sourceUnit, destUnit) {
      if (!destUnit) {
        destUnit = "F";
      }
      if (value == null) {
        return "n/a";
      }
      var tempC = null;
      if (sourceUnit === 'C') {
        tempC = value;
      }
      if (sourceUnit === 'F') {
        tempC = (value - 32) * (5 / 9);
      }
      let result = 'null';
      if (destUnit && destUnit.toLowerCase() === 'f') {
        result = tempC * (9 / 5) + 32;
      }
      if (destUnit && destUnit.toLowerCase() === 'c') {
        result = tempC;
      }

      //result = Math.round(result) + ' '+(destUnit?destUnit:"null");
      result = parseInt(result, 10) + ' ' + (destUnit ? destUnit : "null");
      return result;
    }
  };
});