define("spotassist/mixins/seo-route", ["exports", "spotassist/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    headData: Ember.inject.service(),
    init() {
      this._super();
      this.get('headData').set('prerenderReady0', window.prerenderReady);
    },
    setHeadTags(model, title, description, imageUrl) {
      this.get('headData').set('prerenderReady1', window.prerenderReady);
      Ember.run.scheduleOnce('afterRender', this, () => {
        window.prerenderReady = true;
        this.get('headData').set('prerenderReady2', window.prerenderReady);
      });
      this.get('headData').set('title', title);
      this.get('headData').set('fb_app_id', _environment.default.FB_APP_ID);
      let shareUrl = _environment.default.SHARE_HOST + '/' + window.location.hash; //window.location.href;

      let headTags = [{
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'description',
          content: description
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-description-tag',
        attrs: {
          property: 'og:description',
          content: description
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-title-tag',
        attrs: {
          property: 'og:title',
          content: title
        }
      }, {
        type: 'meta',
        tagId: 'meta-image-tag',
        attrs: {
          property: 'og:image',
          content: imageUrl
        }
      }, {
        type: 'meta',
        tagId: 'meta-url-tag',
        attrs: {
          property: 'og:url',
          content: shareUrl
        }
      }];
      this.set('headTags', headTags);
    }
  });
});