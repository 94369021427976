define("spotassist/models/taf-station", ["exports", "ember-data", "spotassist/config/environment", "moment"], function (_exports, _emberData, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  /* global L */
  var _default = _exports.default = Model.extend({
    name: attr('string'),
    station_id: attr('string'),
    lat: attr('string'),
    lon: attr('string'),
    site: attr('string'),
    issue_time: attr(),
    forecast_url: attr(),
    current_url: attr(),
    type: "TAF",
    location: Ember.computed('lat', 'lon', function () {
      return [this.get('lat'), this.get('lon')];
    }),
    displayName: Ember.computed('site', function () {
      return this.get('site');
    }),
    icon: Ember.computed('upToDate', function () {
      //console.log('icon change for '+this.get('dz_name')+' selected:'+this.get('selected'));
      if (this.get('upToDate')) {
        return new L.icon({
          iconUrl: _environment.default.rootURL + 'assets/images/taf_icon.png'
        });
      } else {
        return new L.icon({
          iconUrl: _environment.default.rootURL + 'assets/images/taf_icon_outdated.png'
        });
      }
    }),
    upToDate: Ember.computed('issue_time', function () {
      var validDate = (0, _moment.default)(this.get('issue_time')).utc();
      var nowValidDate = (0, _moment.default)().utc();
      var diffHours = Math.abs(validDate.diff(nowValidDate, 'hours'));
      return diffHours < 5;
    })
  });
});