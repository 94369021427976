define("spotassist/instance-initializers/ember-dialog", ["exports", "spotassist/config/environment", "ember-dialog/configuration"], function (_exports, _environment, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    _configuration.default.load(_environment.default);
    application.inject('controller', 'dialog', 'service:dialog');
  }
  var _default = _exports.default = {
    name: 'ember-dialog',
    initialize
  };
});