define("spotassist/metrics-adapters/amplitude", ["exports", "ember-metrics/metrics-adapters/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global amplitude */
  var _default = _exports.default = _base.default.extend({
    toStringExtension() {
      return 'amplitude';
    },
    init() {
      //const { apiKey, options } = Ember.get(this, 'config');
      const {
        apiKey
      } = Ember.get(this, 'config');
      //this.setupService(apiKey);
      //this.setOptions(options);

      (function (e, t) {
        var n = e.amplitude || {
          _q: [],
          _iq: {}
        };
        var r = t.createElement("script");
        r.type = "text/javascript";
        r.async = true;
        r.src = "https://d24n15hnbwhuhn.cloudfront.net/libs/amplitude-3.4.0-min.gz.js";
        r.onload = function () {
          e.amplitude.runQueuedFunctions();
        };
        var i = t.getElementsByTagName("script")[0];
        i.parentNode.insertBefore(r, i);
        function s(e, t) {
          e.prototype[t] = function () {
            this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
            return this;
          };
        }
        var o = function () {
          this._q = [];
          return this;
        };
        var a = ["add", "append", "clearAll", "prepend", "set", "setOnce", "unset"];
        for (var u = 0; u < a.length; u++) {
          s(o, a[u]);
        }
        n.Identify = o;
        var c = function () {
          this._q = [];
          return this;
        };
        var p = ["setProductId", "setQuantity", "setPrice", "setRevenueType", "setEventProperties"];
        for (var l = 0; l < p.length; l++) {
          s(c, p[l]);
        }
        n.Revenue = c;
        var d = ["init", "logEvent", "logRevenue", "setUserId", "setUserProperties", "setOptOut", "setVersionName", "setDomain", "setDeviceId", "setGlobalUserProperties", "identify", "clearUserProperties", "setGroup", "logRevenueV2", "regenerateDeviceId", "logEventWithTimestamp", "logEventWithGroups"];
        function v(e) {
          function t(t) {
            e[t] = function () {
              e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
            };
          }
          for (var n = 0; n < d.length; n++) {
            t(d[n]);
          }
        }
        v(n);
        n.getInstance = function (e) {
          e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
          if (!n._iq.hasOwnProperty(e)) {
            n._iq[e] = {
              _q: []
            };
            v(n._iq[e]);
          }
          return n._iq[e];
        };
        e.amplitude = n;
      })(window, document);
      amplitude.getInstance().init(apiKey);
    },
    identify(options) {
      console.info("Amplitude Track identify: " + options.id);
      if (options.id) {
        amplitude.getInstance().setUserId(options.id); // need to reconfigure new app
        amplitude.getInstance().setUserProperties(options);
      }
    },
    trackEvent(options) {
      console.info("Amplitude Track event: " + options.event);
      if (options.event) {
        amplitude.getInstance().logEvent(options.event, options);
      }
    },
    trackPage(options) {
      console.info("Amplitude Track page: " + options.title);
      if (options.title) {
        amplitude.getInstance().logEvent("View " + options.title, options);
      }
    },
    alias() {
      //alias(options) {
    },
    willDestroy() {
      //willDestroy(options) {
    }
  });
});