define("spotassist/components/dropzone-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      markerLocationChanged(event) {
        var dropzone = this.get('dropzone');
        dropzone.set('lat', event.target._latlng.lat);
        dropzone.set('lon', event.target._latlng.lng);
      }
    }
  });
});