define("spotassist/objects/gfs-helper-object", ["exports", "spotassist/config/environment", "moment", "spotassist/objects/datetime-parser-object", "spotassist/objects/gfs-station"], function (_exports, _environment, _moment, _datetimeParserObject, _gfsStation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GfsHelper = Ember.Object.extend({
    datetimeParser: _datetimeParserObject.default.create(),
    init() {
      this._super();
      this.set('__interfaces', "com.livewings.spotassist.library.gfs.IGfsHelper");
    },
    getDatetimeParser() {
      return this.get('datetimeParser');
    },
    getLogReporter() {
      return null;
    },
    createException(message, ex) {
      return null;
    },
    createStation(station_id, station_name, site, lat, lon, stationTypes, metars, taf, windaloft) {
      var windaloftArray = [];
      var flightlevels = [];
      windaloft.flightlevels.forEach(function (flightlevel) {
        flightlevels.push(flightlevel);
      });
      windaloft.flightlevels = flightlevels;
      windaloftArray.push(windaloft);
      var metarArray = [];
      metars.forEach(function (metar) {
        metarArray.push(metar);
      });
      return _gfsStation.default.create({
        station_id: station_id,
        station_name: station_name,
        site: site,
        lat: lat,
        lon: lon,
        station_types: stationTypes,
        metar: metarArray,
        taf: taf,
        windaloft: windaloftArray
      });
    }
  });
  var _default = _exports.default = GfsHelper;
});