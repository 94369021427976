define("spotassist/controllers/auth/password-reset-confirm", ["exports", "spotassist/config/environment", "spotassist/utils/global-error-handler"], function (_exports, _environment, _globalErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    cookies: Ember.inject.service('cookies'),
    actions: {
      setPasswordAction() {
        console.info('setPasswordAction');
        var data = this.getProperties('new_password', 're_new_password');
        var params = this.get('params');
        data.token = params.token;
        data.uid = params.uid;
        var that = this;
        this.set('setProgress', true);
        let csrftoken = this.get('cookies').read('csrftoken');
        Ember.$.ajax({
          type: "POST",
          headers: {
            "X-CSRFToken": csrftoken
          },
          url: _environment.default.adapter_host + '/auth/users/reset_password_confirm/',
          data: data,
          dataType: 'json',
          success: function () {
            console.info("reset success");
            that.set('setProgress', false);
            that.transitionToRoute('auth.password-reset-success');
          },
          error: function (error) {
            var message = 'Unable to reset password with ' + data + ' credentials error:' + JSON.stringify(error.responseJSON);
            console.error(message);
            (0, _globalErrorHandler.default)(error, message);
            that.set('setProgress', false);
            that.set('error', error);
          }
        });
      }
    },
    setDisabled: Ember.computed('new_password', 're_new_password', function () {
      var data = this.getProperties('new_password', 're_new_password');
      return Ember.isEmpty(data.new_password) || Ember.isEmpty(data.re_new_password);
    }),
    usernameErrors: Ember.computed('error', function () {
      if (this.get('error')) {
        var usernameErrors = [];
        var response = this.get('error').responseJSON;
        if (response.username) {
          usernameErrors = response.username;
        }
        return usernameErrors;
      } else {
        return [];
      }
    }),
    emailErrors: Ember.computed('error', function () {
      if (this.get('error')) {
        var emailErrors = [];
        var response = this.get('error').responseJSON;
        if (response.email) {
          emailErrors = response.email;
        }
        return emailErrors;
      } else {
        return [];
      }
    }),
    nonFieldErrors: Ember.computed('error', function () {
      if (this.get('error')) {
        var nonFieldErrors = [];
        var response = this.get('error').responseJSON;
        if (response.non_field_errors) {
          nonFieldErrors = response.non_field_errors;
        }
        return nonFieldErrors;
      } else {
        return [];
      }
    })
  });
});