define("spotassist/components/askmore-button", ["exports", "spotassist/utils/global-error-handler", "spotassist/config/environment"], function (_exports, _globalErrorHandler, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      requestInfoAction() {
        var dropzone = this.get('dropzone');
        dropzone.set('request-progress', true);
        dropzone.set('request-error', false);

        //var requestInfoUrl = ENV.adapter_host+"/crowd-user-dropzones/" + dropzone.get('id') + "/request/?request-more=true";
        var requestInfoUrl = _environment.default.adapter_host + "/crowd-user-dropzones/" + dropzone.get('id') + "/request/?request-more=true";
        Ember.$.ajax(requestInfoUrl).then(function (dropzones) {
          dropzone.set('contacted', dropzones[0].contacted);
          dropzone.set('digested', dropzones[0].digested);
          dropzone.set('request-progress', false);
          dropzone.set('request-error', false);
        }, function (error) {
          var message = "Error requesting info: dropzone";
          console.error(message + error);
          (0, _globalErrorHandler.default)(error, message);
          dropzone.set('request-progress', false);
          dropzone.set('request-error', true);
        });
      }
    },
    progress: Ember.computed('dropzone.request-progress', function () {
      return this.get('dropzone.request-progress');
    }),
    error: Ember.computed('dropzone.request-error', function () {
      return this.get('dropzone.request-error');
    }),
    success: Ember.computed('dropzone.{request-progress,request-error}', function () {
      if (this.get('dropzone.request-progress') == null) {
        return false;
      } else {
        return !this.get('dropzone.request-progress') && !this.get('dropzone.request-error');
      }
    })
  });
});