define("spotassist/routes/dropzones/bigpicture", ["exports", "spotassist/mixins/trackable-route", "spotassist/mixins/seo-route"], function (_exports, _trackableRoute, _seoRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_trackableRoute.default, _seoRoute.default, {
    actions: {
      mapClicked() {
        //console.log('mapClicked: ');

        this.controller.set('markerClicked', false);
        //this.controller.set('selectedDropzone', null);
        this.get('user-settings').closeNav();
      },
      markerLocationChanged(event) {
        var selectedDropzone = this.controller.get('selectedDropzone');
        selectedDropzone.set('lat', event.target._latlng.lat);
        selectedDropzone.set('lon', event.target._latlng.lng);
      },
      openNavAction() {
        this.get('user-settings').openNav();
      },
      closeNavAction() {
        this.get('user-settings').closeNav();
      },
      didTransition() {
        this._super(...arguments);
        var that = this;
        this.store.findRecord('dropzone', this.get('currentModel.id')).then(function (record) {
          that.trackPage({
            title: "Big Picture",
            url: that.get('router.url'),
            dz_id: record.get('id'),
            dz_name: record.get('dz_name')
          });
        });
      }
    },
    activate() {
      //console.log('activate');
      console.info('Bigpicture activated');
      this.controllerFor('dropzones.bigpicture').setRouteActive(true);
      //this.get('controller').set('routeActive', true);
    },
    deactivate() {
      //console.log('deactivate');
      console.info('Bigpicture deactivated');
      this.controllerFor('dropzones.bigpicture').setRouteActive(false);
      //this.get('controller').set('routeActive', false);
    },
    model(params) {
      var dropzone = this.store.findRecord('dropzone', params.dropzone_id);
      return dropzone;
    },
    /*
    afterModel(model) {
      window.prerenderReady = true;
    },
    */

    afterModel(model) {
      let title = model.get('dz_name') + ' Big Picture - Spot Assist';
      let description = 'Install this on the big screen at ' + model.get('dz_name') + '. Current Weather. Exit Area. Pattern. Now you can showcase the jump plan from door to landing.';
      let imageUrl = 'http://www.spotassist.com/wp-content/uploads/2017/04/bigpicture-spotassist.png';
      this.setHeadTags(model, title, description, imageUrl);
    }
  });
});