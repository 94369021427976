define("spotassist/helpers/is-speed-zero", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    //compute(params, hash) {
    compute(params) {
      let speed = params[0];
      return speed <= 0;
    }
  });
});