define("spotassist/components/settings-pulldown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    localSettings: Ember.inject.service('local-settings'),
    metrics: Ember.inject.service(),
    actions: {
      setTemperatureUnitAction(unit) {
        Ember.get(this, 'metrics').trackEvent({
          event: "Set Unit",
          unit: 'temperature',
          value: unit
        });
        this.get('localSettings').set('settings.temperature_unit', unit);
      },
      setSpeedUnitAction(unit) {
        Ember.get(this, 'metrics').trackEvent({
          event: "Set Unit",
          unit: 'speed',
          value: unit
        });
        this.get('localSettings').set('settings.speed_unit', unit);
      },
      setAltitudeUnitAction(unit) {
        Ember.get(this, 'metrics').trackEvent({
          event: "Set Unit",
          unit: 'altitude',
          value: unit
        });
        this.get('localSettings').set('settings.altitude_unit', unit);
      },
      setDistanceUnitAction(unit) {
        Ember.get(this, 'metrics').trackEvent({
          event: "Set Unit",
          unit: 'distance',
          value: unit
        });
        this.get('localSettings').set('settings.distance_unit', unit);
      }
    },
    temperatureUnitF: Ember.computed('localSettings.settings.temperature_unit', function () {
      var temperatureUnit = this.get('localSettings').get('settings.temperature_unit');
      if (!temperatureUnit) {
        return true;
      }
      return temperatureUnit === 'F';
    }),
    temperatureUnitC: Ember.computed('localSettings.settings.temperature_unit', function () {
      var temperatureUnit = this.get('localSettings').get('settings.temperature_unit');
      if (!temperatureUnit) {
        return false;
      }
      return temperatureUnit === 'C';
    }),
    speedUnitKts: Ember.computed('localSettings.settings.speed_unit', function () {
      var speedUnit = this.get('localSettings').get('settings.speed_unit');
      if (!speedUnit) {
        return true;
      }
      return speedUnit === 'kts';
    }),
    speedUnitMs: Ember.computed('localSettings.settings.speed_unit', function () {
      var speedUnit = this.get('localSettings').get('settings.speed_unit');
      if (!speedUnit) {
        return false;
      }
      return speedUnit === 'm/s';
    }),
    altitudeUnitFt: Ember.computed('localSettings.settings.altitude_unit', function () {
      var altitudeUnit = this.get('localSettings').get('settings.altitude_unit');
      if (!altitudeUnit) {
        return true;
      }
      return altitudeUnit === 'ft';
    }),
    altitudeUnitM: Ember.computed('localSettings.settings.altitude_unit', function () {
      var altitudeUnit = this.get('localSettings').get('settings.altitude_unit');
      if (!altitudeUnit) {
        return false;
      }
      return altitudeUnit === 'm';
    }),
    distanceUnitMi: Ember.computed('localSettings.settings.distance_unit', function () {
      var distanceUnit = this.get('localSettings').get('settings.distance_unit');
      if (!distanceUnit) {
        return true;
      }
      return distanceUnit === 'mi';
    }),
    distanceUnitKm: Ember.computed('localSettings.settings.distance_unit', function () {
      var distanceUnit = this.get('localSettings').get('settings.distance_unit');
      if (!distanceUnit) {
        return false;
      }
      return distanceUnit === 'km';
    })
  });
});