define("spotassist/components/login-form", ["exports", "spotassist/config/environment", "spotassist/utils/global-error-handler"], function (_exports, _environment, _globalErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    cookies: Ember.inject.service('cookies'),
    fullPage: false,
    formType: null,
    actions: {
      switch(formType) {
        console.info("switch to " + formType);
        this.set('formType', formType);
      },
      signInAction() {
        if (this.get('loginDisabled')) {
          console.warn('Cannot login without all fields filled');
          return;
        }
        var data = this.getProperties('email', 'password');
        data.username = data.email;
        //if(data.username.length>30) {
        //  data.username = data.username.substring(0, 29);
        //}
        this.doSignIn(data);
      },
      signUpAction() {
        if (this.get('signupDisabled')) {
          console.warn('Cannot signup without all fields filled');
          return;
        }
        var that = this;
        this.set('error', null);
        var data = this.getProperties('fullname', 'email', 'password');
        data.username = data.email;
        var nameParts = data.fullname.split(' ');
        data.first_name = nameParts[0];
        if (nameParts.length > 1) {
          data.last_name = nameParts[1];
        }
        this.set('signupProgress', true);
        let csrftoken = this.get('cookies').read('csrftoken');
        Ember.$.ajax({
          type: "POST",
          headers: {
            "X-CSRFToken": csrftoken
          },
          url: _environment.default.adapter_host + '/auth/users/',
          //url: ENV.adapter_host + '/users/atoken/',
          data: data,
          dataType: 'json',
          success: function () {
            //resolve(user);
            console.info("register success");
            that.set('signupProgress', false);
            that.set('session.signupSuccess', true);
            that.resetFields();
            that.doSignIn(data);
          },
          error: function (error) {
            var message = 'Unable to register user with ' + data + ' credentials error:' + JSON.stringify(error.responseJSON);
            console.error(message);
            (0, _globalErrorHandler.default)(error, message);
            that.set('signupProgress', false);
            that.set('error', error);
          }
        });
      },
      signInViaFacebookAction() {
        console.info('authenticateFacebook');
        /*
        this.get('session').authenticate('authenticator:torii', 'facebook-connect').catch((reason) => {
          this.set('error', reason);
        });
        */
        this.get('session').authenticate('authenticator:torii', 'facebook-connect');
      },
      signInViaGoogleAction() {
        console.info('authenticateGoogle');
        this.get('session').authenticate('authenticator:torii', 'google-oauth2-bearer');
      },
      signout() {
        this.get('session').invalidate();
      }
    },
    doSignIn(data) {
      this.set('error', null);
      var that = this;
      this.get('session').authenticate('authenticator:drf-token-authenticator', data).then(function () {
        that.resetFields();
      }).catch(reason => {
        that.set('error', reason);
      });
    },
    typeLogin: Ember.computed('formType', function () {
      return this.get('formType') === 'login';
    }),
    typeSignup: Ember.computed('formType', function () {
      return this.get('formType') === 'signup';
    }),
    fullnameReady: Ember.computed('fullname', function () {
      return !Ember.isEmpty(this.get('fullname'));
    }),
    emailReady: Ember.computed('email', function () {
      return !Ember.isEmpty(this.get('email'));
    }),
    passwordReady: Ember.computed('password', function () {
      return !Ember.isEmpty(this.get('password'));
    }),
    resetFields() {
      this.set('fullname', null);
      this.set('email', null);
      this.set('password', null);
    },
    signupDisabled: Ember.computed('fullname', 'email', 'password', function () {
      var data = this.getProperties('fullname', 'email', 'password');
      return Ember.isEmpty(data.fullname) || Ember.isEmpty(data.email) || Ember.isEmpty(data.password);
    }),
    loginDisabled: Ember.computed('email', 'password', function () {
      var data = this.getProperties('email', 'password');
      return Ember.isEmpty(data.email) || Ember.isEmpty(data.password);
    }),
    usernameErrors: Ember.computed('error', function () {
      if (this.get('error')) {
        var usernameErrors = [];
        var response = this.get('error').responseJSON;
        if (response && response.username) {
          usernameErrors = response.username;
        }
        return usernameErrors;
      } else {
        return [];
      }
    }),
    emailErrors: Ember.computed('error', function () {
      if (this.get('error')) {
        var emailErrors = [];
        var response = this.get('error').responseJSON;
        if (response && response.email) {
          emailErrors = response.email;
        }
        return emailErrors;
      } else {
        return [];
      }
    }),
    nonFieldErrors: Ember.computed('error', function () {
      if (this.get('error')) {
        var nonFieldErrors = [];
        var response = this.get('error').responseJSON;
        if (response && response.non_field_errors) {
          nonFieldErrors = response.non_field_errors;
        }
        return nonFieldErrors;
      } else {
        return [];
      }
    })
  });
});