define("spotassist/routes/index", ["exports", "spotassist/utils/global-error-handler", "spotassist/mixins/seo-route"], function (_exports, _globalErrorHandler, _seoRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_seoRoute.default, {
    actions: {
      error: _globalErrorHandler.default
    },
    beforeModel() {
      //this.transitionTo('dropzones.index');
      this.transitionTo('what-is-bigpicture');
      //this.transitionTo('dropzones.crowd');
    },
    afterModel(model) {
      let title = 'Spot Assist World Dropzones Atlas';
      let description = 'Crowd sourced dropzones around the world. Jumpers fine tune the data daily - landing target, landing direction etc.';
      let imageUrl = 'http://www.spotassist.com/wp-content/uploads/2017/02/dropzones-spotassist.png';
      this.setHeadTags(model, title, description, imageUrl);
    }
  });
});